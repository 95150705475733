<template>
    <div class="detail-wrap">
        <div class="content">
            <!-- 问题详情 -->
            <sv-card title="问题详情" :showLoading="showLoading">
                <span slot="extra">
                    <span class="top">咨询编号：{{ baseData.id }}</span>
                    <span class="top">提问时间：{{ baseData.createdOn }}</span>
                    <span class="top">问题状态：{{ baseData.replyStatusName }}</span>
                </span>
                <div slot="body" class="baseForm">
                    <sv-panel-form ref="baseForm" :labelWidth="120">
                        <item type="row">
                            <item type="col">
                                <item label="问题分类" type="displayfield" name="consultTypeName" />
                                <item label="品牌" type="displayfield" name="brandName" />
                                <item label="车系" type="displayfield" name="seriesName" />
                            </item>
                            <item type="col">
                                <item label="配件号" type="displayfield" name="partCode" />
                                <item label="配件名称" type="displayfield" name="partName" span="16" />
                            </item>
                            <item type="col">
                                <item label="手册类型" type="displayfield" name="classifyName" />
                                <item label="手册章节" type="displayfield" name="manualChapter" />
                                <item label="手册页码" type="displayfield" name="manualPage" />
                            </item>
                            <item type="col">
                                <item label="问题描述" type="displayfield" name="questionDesc" span="16" />
                            </item>
                            <item type="col">
                                <item label="附件" type="images" name="resList" span="24" />
                            </item>
                        </item>

                        <template #resList="data">
                            <!-- 7.图片 -->
                            <div style="display:flex">
                                <myImage
                                    v-for="item in data.data"
                                    :item="item"
                                    :key="item.id"
                                    :width="80"
                                    :height="80"
                                    :src="item.path"
                                    :extension="item.extension"
                                    :isShowDownload="false">
                                </myImage>
                            </div>

                        </template>
                    </sv-panel-form>

                    <div class="btnField">
                        <Button class="btn" type="primary" @click="close" v-if="baseData.closeStatus == '0'">关闭</Button>
                    </div>
                </div>
            </sv-card>

            <!-- 咨询历史 -->
            <ChatInfo
                :isEdit="true"
                @submited="loadBaseData"
                :baseData="baseData"
                :loadUrl="`/consult/engineering/consult/chat/history?id=${$route.query.id}`"
                submitUrl="/consult/engineering/consult/chat/reply"
                url="/api/consult/res/upload" />
        </div>
    </div>
</template>
<script>
import { request } from '@/network/request';
import ChatInfo from './common/chatInfo.vue';
import myImage from '../common/my-image.vue';

export default {
    name: 'M001-1',
    components: {
        ChatInfo, myImage
    },
    data() {
        return {
            title: document.title,
            baseData: {},
            showLoading: false
        };
    },

    computed: {},

    created() {
        this.loadBaseData();
    },

    methods: {
        loadBaseData() {
            const url = '/consult/engineering/consult/detail?id=' + this.$route.query.id;
            this.showLoading = true;
            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        const { result } = data;
                        this.baseData = result || {};
                        this.$nextTick(() => {
                            this.$refs.baseForm.setValues(result);
                        });
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        close() {
            const params = { id: this.baseData.id }

            request
                .post('/consult/engineering/consult/close', params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('关闭成功');
                        this.loadBaseData();
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        }
    }
};
</script>

<style lang="less" scoped>
.detail-wrap {
    height: 100%;
    min-width: 1200px;
    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #4e4e4e;
        height: 34px;
        line-height: 34px;
        padding: 0 6px;
        font-weight: 600;
        background: #e4e4e4;

        span {
            margin-right: 50px;
        }
    }

    .content {
        flex: 1;
        padding: 5px 0;

        .top {
            margin-right: 50px;
        }
    }

    /deep/.ivu-card-head {
        padding-left: 10px;
        background: #e4e4e4;
    }

    /deep/.displayfield {
        margin-top: 4px;
    }

    /deep/.images-wrap {
        margin-right: 10px;
    }

    /deep/ .image-class {
        height: 100%;
    }

    /deep/ .ivu-form-item {
        height: 100%;
    }

    .btnField {
        display: flex;

        .btn {
            margin: 0 auto;
        }
    }

}
</style>
